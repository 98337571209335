import axios from "./clientSetup";

const { localStorage } = window;

export function storeUserData(userData){
    localStorage.setItem('user', JSON.stringify(userData));
}

export function loadProfile(){
    return axios.get(`/api/my/profile`);
}

export function authenticateUser(email, password, shouldRemember){
    const request = axios
        .post('/api/auth/login', {
            username: email,
            password,
            remember: shouldRemember
        });
    request
        .then((response) => {
            localStorage.setItem('authentication', JSON.stringify(response.data.jwt));
            storeUserData(response.data.user);
        });

    return request;
}
export function clearAuthentication(){
    localStorage.removeItem('authentication');
    localStorage.removeItem('user');
}
export function getAuthentication(){
    try {
        const authentication = JSON.parse(localStorage.getItem('authentication'));
        if(authentication && authentication.expireTime < Math.floor(Date.now() / 1000)){
            clearAuthentication();
            return null;
        }
        return authentication;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return null;
    }
}
export function getAuthenticatedUser(){
    try {
        getAuthentication();
        const userData = localStorage.getItem('user');
        return userData !== null ? JSON.parse(userData) : null;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return null;
    }
}

export function getMyBaseUrls(page, rowsPerPage){
    return axios.get(`/api/my/bases?page=${page}&pageSize=${rowsPerPage}&sortBy=baseurl&sortDirection=asc`);
}

export function registerBaseUrl(data){
    return axios
        .post('/api/my/bases', data);
}

export function deleteBaseUrl(id){
    return axios
        .delete(`/api/my/bases/${id}`);
}

export function forgotPassword(username){
    return axios.post(`/api/auth/forgot`, {username});
}

export function recoveryPassword(body){
    return axios.post(`/api/auth/reset`, body);
}
