import {Helmet} from "react-helmet-async";
import {useContext, useState} from "react";
import {
    Box,
    Container,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import KeyIcon from '@mui/icons-material/Key';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AppsIcon from '@mui/icons-material/Apps';
import {UserContext} from "../../contexts/contexts";
import MyInfoDescription from "./tabs/DescriptionOfMyInfo";
import EditingMyInfo from "./tabs/EditingMyInfo";
import ChangeMyPassword from "./tabs/ChangePassword";

function LinkTab(props) {
    const navigate = useNavigate();
    const {href} = props;
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
                navigate(href);
            }}
            {...props}
        />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const profileTabs = [
    {
        icon: <AppsIcon />,
        label: 'Profile',
        url: '/account/overview',
    },
    {
        icon: <AccountBoxIcon />,
        label: 'Edit account',
        url: '/account/edit',
    },
    {
        icon: <KeyIcon />,
        label: 'Change password',
        url: '/account/change-password',
    },
];

export default function AccountEditPage() {
    const location = useLocation();
    const account = useContext(UserContext);

    let originalActiveTab = 0;
    profileTabs.forEach((item,index) => {
        if(item.url === location.pathname) {
            originalActiveTab = index;
        }
    });
    const [activeTab, setActiveTab] = useState(originalActiveTab);

    // const changePersonalInfo = (userData) => adminUpdateUser(account.id, userData);
    const changePersonalInfo = (userData) => console.log(userData);

    const changePassword = (userData) => console.log(userData);

    return (
        <>
            <Helmet>
                <title> Account </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Account
                    </Typography>
                </Stack>

                <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={(event, newTab) => {setActiveTab(newTab);}} aria-label="account tabs" centered>
                        {profileTabs.map((item, index) => (<LinkTab key={index} icon={item.icon} iconPosition="start" label={item.label} href={item.url} />))}
                    </Tabs>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <MyInfoDescription value={account}/>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <EditingMyInfo userInfo={account} formSubmitCallback={changePersonalInfo}/>
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <ChangeMyPassword formSubmitCallback={changePassword}/>
                </TabPanel>
            </Container>
        </>
    );
}