import {useNavigate, useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Helmet} from "react-helmet-async";
import {Card, Container, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import {adminCreateUser, adminGetUserDetails, adminUpdateUser} from "../../api/adminApi";
import UserForm from "../../sections/user/admin-form/UserForm";


const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function UserEditPage() {
    const {id} = useParams();
    const [userData, setUserData] = useState(null);
    const [loadingUserData, setLoadingUserData] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoadingUserData(true);
        adminGetUserDetails(id)
            .then((response) => {
                setUserData(response.data);
                setLoadingUserData(false);
            });
    }, []);

    const processUserUpdate = (userData) => {
        const request = adminUpdateUser(id, userData)
        request
            .then(() => {navigate('/admin/users/', { replace: true });});

        return request;
    };
    return (
        <>
            <Helmet>
                <title> Modify User </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Modify {userData && userData.username}
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        {
                            loadingUserData ? '' : (
                                <StyledContent>
                                    <UserForm userData={userData} formSubmitCallback={processUserUpdate} rePasswordEnable={false} actionLabel="Update" />
                                </StyledContent>
                            )
                        }
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}