import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    Autocomplete,
    Avatar, Button,
    Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Stack,
    TextField, Typography
} from "@mui/material";
import AdminUserPicker from "../../../components/admin-user-picker";
import AdminBaseurlPicker from "../../../components/admin-baseurl-picker";

export default function ClientForm({modelData, formSubmitCallback, actionLabel, returnUrl, showKeys}) {
    const navigate = useNavigate();

    const [title, setTitle] = useState(modelData ? modelData.title : '');
    const [owner, setOwner] = useState(modelData ? modelData.owner : null);
    const [baseUrls, setBaseUrls] = useState(modelData ? modelData.baseUrls : []);
    const [enabled, setEnabled] = useState(modelData ?modelData.enabled : false);

    const [isProcessing, setIsProcessing] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleSubmit = (event) => {
        event.preventDefault();

        setIsProcessing(true);
        formSubmitCallback({
            title,
            ownerId: owner.id,
            enabled,
            baseIds: baseUrls.map((baseurlModel) => baseurlModel.id)
        }).then(() => {
            setValidationErrors({});
            setIsProcessing(false);
        })
            .catch((error) => {
                if(error.response.data.errors){
                    setValidationErrors(error.response.data.errors);
                }
                setIsProcessing(false);
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                {showKeys && <>
                    <TextField
                        readonly
                        disabled
                        aria-disabled
                        name="apikey"
                        label="Api Key"
                        value={modelData ? modelData.apikey : ''}
                    />
                    <TextField
                        readonly
                        disabled
                        name="apiToken"
                        label="Api Secret"
                        value={modelData ? modelData.apiToken : ''}
                    />
                </>}
                <TextField
                    required
                    name="title"
                    label="Title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    helperText={validationErrors.title ? validationErrors.title : ''}
                    error={Boolean(validationErrors.title)}
                />
                <AdminUserPicker value={owner} onChange={setOwner} />
                <AdminBaseurlPicker value={baseUrls} onChange={setBaseUrls} />
                <FormControl>
                    <FormLabel>Is Enabled</FormLabel>
                    <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {setEnabled(!enabled)}} />} label="Is Enabled" />
                </FormControl>
                <Stack spacing={1} direction="row" justifyContent="right">
                    {!isProcessing ? <Button type="submit" variant="contained">{actionLabel}</Button> : <Button type="button" disabled variant="contained"><CircularProgress size="1rem" color="inherit" /> &nbsp; {actionLabel}</Button>}
                    <Button variant="text" color="secondary" onClick={(event) => {event.preventDefault();navigate(returnUrl);}}>Cancel</Button>
                </Stack>
            </Stack>
        </form>)
}