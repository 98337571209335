import {useContext, useState} from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';

import {authenticateUser} from "../../api/userApi";
import {SetUserContext} from "../../contexts/contexts";
import ProcessingButton from "../../components/custom_components/processing-button/ProcessingButton";
import AlertMessage from "../../components/custom_components/alerts/AlertMessage";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const userStateSetter = useContext(SetUserContext);
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsProcessing(true);
        authenticateUser(
            email,
            password,
            rememberMe
        ).then((response) => {
            setErrorMessage(null);
            setIsProcessing(false);
            console.log(response);
            userStateSetter(response.data.user);
            navigate('/', {replace: true});
        }).catch((error) => {
            console.log(error);
            setIsProcessing(false);
            setErrorMessage(error.response.data || 'Unknown error happened');
        });

    };

    return (<form onSubmit={handleSubmit}>
            <AlertMessage message={errorMessage} severity="error"/>
            <Stack spacing={3}>
                <TextField name="email" label="Email address" onChange={(event) => setEmail(event.target.value)}/>

                <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>),
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                <FormControlLabel
                    control={<Checkbox name="remember" label="Remember me" checked={rememberMe} onChange={() => {
                        setRememberMe(!rememberMe)
                    }}/>} label="Remember me"/>
                <Link variant="subtitle2" underline="hover" component={RouterLink} to="/forgot-password">
                    Forgot password?
                </Link>
            </Stack>
            <ProcessingButton title="Login" processing={isProcessing}/>
        </form>);
}
