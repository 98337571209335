import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {styled} from "@mui/material/styles";
import {Card, Container, Paper, Stack, Typography} from "@mui/material";
import Scrollbar from "../../components/scrollbar/Scrollbar";

import {adminGetClientDetails, adminUpdateClient} from "../../api/adminApi";
import ClientForm from "../../sections/client/admin-form/ClientForm";


const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function ClientEditPage() {
    const navigate = useNavigate();
    const returnPath = '/admin/clients';

    const {id} = useParams();
    const [loadingModel, setLoadingModel] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [modifyModel, setModifyModel] = useState(null);

    useEffect(() => {
        setLoadingModel(true);
        adminGetClientDetails(id)
            .then((response) => {
                setModifyModel(response.data);
                setLoadingModel(false);
                setLoadingError(false)
            })
            .catch((error) => {
                setLoadingError(true);
                setLoadingModel(false);;
                console.log(error);
            });
    }, []);

    const handleFormSubmit = (data) => {
        const request = adminUpdateClient(id, data)
        request
            .then(() => {navigate(returnPath, { replace: true });});

        return request;
    };

    return (
        <>
            <Helmet>
                <title> Modify client </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Modify {modifyModel && modifyModel.title}
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <StyledContent>
                        {
                            loadingModel ? '' : (
                                loadingError
                                    ? (<Paper sx={{textAlign: 'center',}} >
                                        <Typography variant="h6" paragraph>
                                            Can not load data from server
                                        </Typography>

                                        <Typography variant="body2">
                                            Most probably you need to check your network connection. Or we already working on solving server issue.
                                        </Typography>
                                    </Paper>)
                                    : (<ClientForm actionLabel="Update" returnUrl={returnPath} modelData={modifyModel} formSubmitCallback={handleFormSubmit} showKeys />)
                            )
                        }
                        </StyledContent>
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}