import { Avatar, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import moment from "moment";
import Label from "../../../components/label";

export default function MyInfoDescription(props) {
    const { avatar, email, username, createdAt, enabled, modifiedAt, lastLoginTime, authorities } = props.value;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Card sx={{display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "20rem"}}>
                    <Avatar src={avatar} sx={{width: "8rem", height:"8rem"}} />
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Card sx={{mb: 3}}>
                    <CardHeader title='Your account details' />
                </Card>
                <Card>
                    <CardContent>
                        <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                email:
                            </Typography>
                            <Typography style={{display: 'inline-block'}} gutterBottom variant="body2" color="text.secondary">
                                {email}
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                username:
                            </Typography>
                            <Typography style={{display: 'inline-block'}} gutterBottom variant="body2" color="text.secondary">
                                {username}
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                enabled:
                            </Typography>
                            <Typography style={{display: 'inline-block'}} gutterBottom variant="body2" color="text.secondary">
                                <Label color={enabled ? 'success' : 'error'}>{enabled ? 'Yes' : 'No'}</Label>
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                date of creating:
                            </Typography>
                            <Typography style={{display: 'inline-block'}} gutterBottom variant="body2" color="text.secondary">
                                {createdAt ? moment(createdAt).format("MMM Do YYYY, HH:mm:ss") : 'N/A'}
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                date of last modifying:
                            </Typography>
                            <Typography style={{display: 'inline-block'}} gutterBottom variant="body2" color="text.secondary">
                                {modifiedAt ? moment(modifiedAt).format("MMM Do YYYY, HH:mm:ss") : 'N/A'}
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                last login time:
                            </Typography>
                            <Typography style={{display: 'inline-block'}} gutterBottom variant="body2" color="text.secondary">
                                {lastLoginTime ? moment(lastLoginTime).fromNow() : 'N/A'}
                            </Typography>
                        </div>
                        {authorities?.includes('ROLE_ADMIN') && <div>
                            <Typography sx={{mr: 3}} style={{display: 'inline-block'}} variant="h5" component="div">
                                authorities:
                            </Typography>
                            {authorities.map((authority, index) =>
                                <Typography
                                    key={index}
                                    sx={{mr: 1}}
                                    style={{display: 'inline-block'}}
                                    gutterBottom
                                    variant="body2"
                                    color="text.secondary"
                                ><Label>{authority}</Label></Typography>)}
                        </div>}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}