import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {registerBaseUrl} from "../../../api/userApi";

export default function BaseUrlRegisterForm() {
    const [domain, setDomain] = useState('');
    const [baseurl, setBaseurl] = useState('/');
    const [httpsEnabled, setHttpsEnabled] = useState(true);
    const [shared, setShared] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();
    const returnUrl = '/dashboard/bases';

    const handleSubmit = (event) => {
        event.preventDefault();
        registerBaseUrl({
            domain,
            baseurl,
            httpsEnabled,
            shared
        })
        .then(() => {
            setValidationErrors({});
            setIsProcessing(false);
            navigate(returnUrl);
        })
        .catch((error) => {
            if(error.response.data.errors){
                setValidationErrors(error.response.data.errors);
            }
            setIsProcessing(false);
        });
    };
    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                <TextField
                    required
                    name="domain"
                    label="Domain"
                    value={domain}
                    onChange={(event) => setDomain(event.target.value)}
                    helperText={validationErrors.domain ? validationErrors.domain : ''}
                    error={Boolean(validationErrors.domain)}
                />
                <TextField
                    required
                    name="baseurl"
                    label="Base path"
                    value={baseurl}
                    onChange={(event) => setBaseurl(event.target.value)}
                    helperText={validationErrors.baseurl ? validationErrors.baseurl : ''}
                    error={Boolean(validationErrors.baseurl)}
                />
                <FormControl>
                    <FormLabel id="https-enabled-switch-label">Protocol</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="https-enabled-switch"
                        name="httpsEnabled"
                    >
                        <FormControlLabel value="true" control={<Radio checked={httpsEnabled} />} label="https" onClick={()=>{setHttpsEnabled(true)}} />
                        <FormControlLabel value="false" control={<Radio checked={!httpsEnabled} />} label="http" onClick={()=>{setHttpsEnabled(false)}} />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel id="shared-switch-label">Visibility</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="shared-switch"
                        name="shared"
                    >
                        <FormControlLabel value="true" control={<Radio checked={shared} />} label="Public use" onClick={()=>{setShared(true)}} />
                        <FormControlLabel value="false" control={<Radio checked={!shared} />} label="Private" onClick={()=>{setShared(false)}} />
                    </RadioGroup>
                </FormControl>
                <Stack spacing={1} direction="row" justifyContent="right">
                    {!isProcessing ? <Button type="submit" variant="contained">Register</Button> : <Button type="button" disabled variant="contained"><CircularProgress size="1rem" color="inherit" /> &nbsp; Processing</Button>}
                    <Button variant="text" color="secondary" onClick={(event) => {event.preventDefault();navigate(returnUrl);}}>Cancel</Button>
                </Stack>
            </Stack>
        </form>
    );
}