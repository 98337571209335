import { Helmet } from 'react-helmet-async';
import {useEffect, useState} from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  styled
} from '@mui/material';
import {Alert} from "@mui/lab";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { DataGridHead, DataGridToolbar } from '../../components/data-grid';
import {adminDeleteUser, adminGetUsers, adminSetUserEnabled} from "../../api/adminApi";


const tableHead = [
  { id: 'username', label: 'User name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'authorities', label: 'Roles', alignRight: false },
  { id: 'lastLoginTime', label: 'Last Login', alignRight: false },
  { id: 'lastLoginAddr', label: 'Last Ip', alignRight: false },
  { id: 'createdAt', label: 'Created', alignRight: false },
  { id: 'enabled', label: 'Enabled', alignRight: false },
  { id: '' },
];

const StyledLabel = styled(Label)(() => ({
  cursor: 'pointer'
}));

export default function UsersPage() {
  const navigate = useNavigate();

  const [userList, setUserList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [orderBy, setOrderBy] = useState('lastLoginTime');
  const [order, setOrder] = useState('desc');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [filterEmail, setFilterEmail] = useState('');

  const [loadingList, setLoadingList] = useState(true);
  const loadUsersData = () => {
    setLoadingList(true);
    adminGetUsers(page+1, rowsPerPage, orderBy, order, filterEmail).then((response) => {
      setUserList(response.data.items);
      setTotalRows(response.data.pagination.totalCount);
      setLoadingList(false);
    });
  }

  useEffect(() => {
    loadUsersData();
  }, [page, rowsPerPage, orderBy, order, filterEmail]);
  const emptyRows = page > 0 ? (rowsPerPage - userList.length) : 0;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(null);
  const [open, setOpen] = useState(null);
  const [openUser, setOpenUser] = useState(null);

  const handleCloseMenu = () => {
    setOpen(null);
    setOpenUser(null);
  };
  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(null);
  }

  const handleEditUser = () => {
    navigate(`${openUser.id}/edit`);
    handleCloseMenu();
  }
  const handleDeleteUserConfirm = () => {
    setDeleteConfirmOpen(openUser);
    handleCloseMenu();
  }
  const handleDeleteUser = (user) => {
    handleDeleteConfirmClose();
    setLoadingList(true);
    adminDeleteUser(user.id)
        .then(()=>{
          loadUsersData();
        })
        .catch((error) => {
          setLoadingList(false);
          console.log(error);
        })
  }

  const handleEnableDisableUser = (row) => {
    adminSetUserEnabled(row.id, !row.enabled)
        .then((response) => {
          setUserList(userList.map(u => u.id !== response.data.id ? u : response.data));
        })
        .catch((error) => {console.error(error)});
  }

  const isNotFound = !userList.length && !!filterEmail;

  return (
      <>
        <Helmet>
          <title> Users </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={()=>{navigate('new', { replace: true })}}>
              New User
            </Button>
          </Stack>

          <Card>
            <DataGridToolbar filterValue={filterEmail} onFilterChange={setFilterEmail} placeholder="Search email" />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <DataGridHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={tableHead}
                      rowCount={totalRows}
                      onRequestSort={handleRequestSort}
                  />
                  {!loadingList
                    ? (<TableBody>
                      {userList.map((row) => {
                        const { authorities, email, enabled, id, lastLoginAddr, lastLoginTime, createdAt, username, avatar } = row;

                        return (
                            <TableRow hover key={id} tabIndex={-1}>
                              <TableCell component="th" scope="row" sx={{ l: 1 }}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={username} src={avatar} />
                                  <Typography variant="subtitle2" noWrap>
                                    {username}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{authorities.reduce((output, role) => {
                                output.push(<Label key={role} sx={{ m: 0.25 }}>{role}</Label>);
                                return output;
                              }, [])}</TableCell>

                              <TableCell align="left">{lastLoginTime ? moment(lastLoginTime).format("MMM Do YYYY, HH:mm:ss") : 'N/A'}</TableCell>
                              <TableCell align="left">{lastLoginAddr}</TableCell>
                              <TableCell align="left">{createdAt ? moment(createdAt).format("MMM Do YYYY, HH:mm:ss") : 'N/A'}</TableCell>

                              <TableCell align="left">
                                <StyledLabel color={enabled ? 'success' : 'error'} onClick={() => {handleEnableDisableUser(row)}}>{enabled ? 'Yes' : 'No'}</StyledLabel>
                              </TableCell>

                              <TableCell align="right">
                                <IconButton size="large" color="inherit" onClick={(event)=>{setOpen(event.currentTarget);setOpenUser(row);}}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={9} />
                          </TableRow>
                      )}
                    </TableBody>)
                      : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                          >
                            <CircularProgress size="5rem" color="secondary" />
                            <Typography variant="h6" paragraph color="secondary" >
                              Loading
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                            <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterEmail}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {setPage(0);setRowsPerPage(parseInt(event.target.value, 10))}}
            />
          </Card>
        </Container>

        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
        >
          <MenuItem onClick={handleEditUser}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteUserConfirm}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>

        <Dialog open={Boolean(deleteConfirmOpen)} onClose={handleDeleteConfirmClose}>
          <DialogTitle justifyContent="space-between">
              Delete {deleteConfirmOpen ? deleteConfirmOpen.username : ''} ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText align="center">
              <Alert severity="warning">
                <strong color="error">This action can not be undone.</strong>
              </Alert>
              <Box m={2} pt={2}>
                <Typography variant="subtitle1">
                  Are you sure you want to delete "{deleteConfirmOpen ? deleteConfirmOpen.username : ''}" user ?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{handleDeleteUser(deleteConfirmOpen)}} autoFocus variant="text" color="error">Delete</Button>
            <Button onClick={handleDeleteConfirmClose} variant="text" color="primary">Cancell</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
