import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import moment from "moment/moment";
import {
    Avatar,
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    MenuItem,
    Paper,
    Popover,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow, Tooltip,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {Alert} from "@mui/lab";
import Label from '../../components/label';
import Iconify from "../../components/iconify";
import {DataGridHead, DataGridToolbar} from "../../components/data-grid";
import Scrollbar from "../../components/scrollbar";
import {
    adminDeleteClient,
    adminGetClientList,
    adminPathClient,
    adminResetClientStatsReporting
} from "../../api/adminApi";
import ApiKeyContainer from "../../components/api-key-container";



const BaseurlLabel = styled(Label)(() => ({
    textTransform: "none",
    marginBottom: "0.25rem"
}));

const StyledLabel = styled(Label)(() => ({
    cursor: 'pointer'
}));

const tableHead = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'baseIds', label: 'Base urls', alignRight: false },
    { id: 'apikey', label: 'Api Key', alignRight: false },
    { id: 'apiToken', label: 'Api Secret', alignRight: false },
    { id: 'enabled', label: 'Is snabled', alignRight: false },
    { id: 'createdAt', label: 'Created', alignRight: false },
    { id: 'ownerId', label: 'Owner', alignRight: false },
    { id: '' },
]

export default function ClientsPage() {
    const navigate = useNavigate();

    const [filterTitle, setFilterTitle] = useState('');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [order, setOrder] = useState('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [loadingDataGrid, setLoadingDataGrid] = useState(true);
    const [dataGridData, setDataGridData] = useState([]);

    const emptyRows = page > 0 ? (rowsPerPage - dataGridData.length) : 0;

    const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState(null);
    const [rowActionsItem, setRowActionsItem] = useState(null);
    const [deleteConfirmRowItem, setDeleteConfirmRowItem] = useState(null);
    const [resetStatsConfirmRowItem, setResetStatsConfirmRowItem] = useState(null);

    const isNotFound = !dataGridData.length && !!filterTitle;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const loadDataGrid = () => {
        setLoadingDataGrid(true);
        adminGetClientList(page+1, rowsPerPage, orderBy, order, filterTitle).then((response) => {
            setDataGridData(response.data.items);
            setTotalRows(response.data.pagination.totalCount);
            setLoadingDataGrid(false);
        });
    }

    const handlePathItem = (id, field, value) => {
        adminPathClient(id, field, value)
            .then((response) => {
                setDataGridData(dataGridData.map(item => item.id !== response.data.id ? item : response.data));
            })
            .catch((error) => {console.error(error)});
    }

    const handleCloseRowActions = () => {
        setRowActionsAnchorEl(null);
        setRowActionsItem(null);
    }

    const handleEditRowItem = () => {
        navigate(`${rowActionsItem.id}/edit`);
        handleCloseRowActions();
    }

    const handleDeleteRowConfirm = () => {
        setDeleteConfirmRowItem(rowActionsItem);
        handleCloseRowActions();
    }
    const handleDeleteConfirmClose = () => {
        setDeleteConfirmRowItem(null);
    }
    const handleDeleteRowItem = (item) => {
        handleDeleteConfirmClose();
        setLoadingDataGrid(true);
        adminDeleteClient(item.id)
            .then(()=>{
                loadDataGrid();
            })
            .catch((error) => {
                setLoadingDataGrid(false);
                console.log(error);
            })
    }
    const handleResetClientStatsConfirm = () => {
        setResetStatsConfirmRowItem(rowActionsItem);
        handleCloseRowActions();
    }
    const handleStatsResetRowItem = (item) => {
        handleResetClientStatsConfirm();
        setLoadingDataGrid(true);
        adminResetClientStatsReporting(item.id)
            .then(()=>{
                loadDataGrid();
            })
            .catch((error) => {
                setLoadingDataGrid(false);
                console.log(error);
            })
    }

    useEffect(() => {
        loadDataGrid();
    }, [page, rowsPerPage, orderBy, order, filterTitle]);

    return (
        <>
            <Helmet>
                <title> Api clients </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Api clients
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={()=>{navigate('new', { replace: true })}}>
                        Add client
                    </Button>
                </Stack>
                <Card>
                    <DataGridToolbar filterValue={filterTitle} onFilterChange={setFilterTitle} placeholder="Search title" />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <DataGridHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={tableHead}
                                    rowCount={totalRows}
                                    onRequestSort={handleRequestSort}
                                />
                                {!loadingDataGrid
                                    ? (<TableBody>
                                        {dataGridData.map((dataGridItem) => {
                                            const { id, owner, enabled, title, apikey, apiToken, baseIds, createdAt, baseUrls } = dataGridItem;
                                            return (
                                                <TableRow hover key={id} tabIndex={-1}>
                                                    <TableCell align="left" sx={{minWidth: "11rem"}}>{title}</TableCell>
                                                    <TableCell align="left">
                                                        {baseUrls.map((baseurl) => {
                                                            let labelColor = 'success'
                                                            let errorDescription = 'Base url ok';
                                                            if(!baseurl.enabled){
                                                                labelColor = 'error';
                                                                errorDescription = 'Base url disabled';
                                                            }
                                                            if(!baseurl.shared && baseurl.ownerId === owner.id){
                                                                labelColor = 'warning'
                                                                errorDescription = 'Base url not public and belongs to another user'
                                                            }

                                                            const idPosition = baseIds.indexOf(baseurl.id);
                                                            if(idPosition !== -1){
                                                                baseIds.splice(idPosition, 1);
                                                            }

                                                            return <Tooltip key={`${baseurl.id}`} title={errorDescription}>
                                                                <BaseurlLabel color={labelColor}>{baseurl.domainUrl}</BaseurlLabel>
                                                            </Tooltip>
                                                        })}
                                                        {baseIds.length !== 0 && (<BaseurlLabel color="error">Has missing bases</BaseurlLabel>)}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <ApiKeyContainer value={apikey} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <ApiKeyContainer value={apiToken} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <StyledLabel color={enabled ? 'success' : 'error'} onClick={() => {handlePathItem(id, 'enabled', !enabled)}}>
                                                            {enabled ? 'Yes' : 'No'}
                                                        </StyledLabel>
                                                    </TableCell>
                                                    <TableCell align="left">{createdAt ? moment(createdAt).format("MMM Do YYYY, HH:mm:ss") : 'N/A'}</TableCell>
                                                    <TableCell component="th" scope="row" sx={{ l: 1 }}>
                                                        {
                                                            owner
                                                                ? (<Tooltip title={owner.username}>
                                                                    <Avatar alt={owner.username} src={owner.avatar} />
                                                                </Tooltip>)
                                                                : (<Typography variant="subtitle2" noWrap>
                                                                    N/A
                                                                </Typography>)
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton size="large" color="inherit" onClick={(event)=>{setRowActionsAnchorEl(event.currentTarget);setRowActionsItem(dataGridItem)}}>
                                                            <Iconify icon={'eva:more-vertical-fill'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={tableHead.length} />
                                            </TableRow>
                                        )}
                                    </TableBody>)
                                    : (<TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                                                <Paper sx={{ textAlign: 'center' }}>
                                                    <CircularProgress size="5rem" color="secondary" />
                                                    <Typography variant="h6" paragraph color="secondary" >
                                                        Loading
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>)
                                }
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No records found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterTitle}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {setPage(0);setRowsPerPage(parseInt(event.target.value, 10))}}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(rowActionsAnchorEl)}
                anchorEl={rowActionsAnchorEl}
                onClose={handleCloseRowActions}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleResetClientStatsConfirm}>
                    <Iconify icon={'eva:repeat-outline'} sx={{ mr: 2 }} />
                    ReStats
                </MenuItem>

                <MenuItem onClick={handleEditRowItem}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Modify
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteRowConfirm}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>

            <Dialog open={Boolean(deleteConfirmRowItem)} onClose={handleDeleteConfirmClose}>
                <DialogTitle justifyContent="space-between">
                    Delete {deleteConfirmRowItem ? `"${deleteConfirmRowItem.title}"` : ''} ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        <Alert severity="warning">
                            <Typography color="error"><strong>This action can not be undone.</strong></Typography>
                            <Typography>Client itself and all shortened urls would be removed from database.</Typography>
                        </Alert>
                        <Box m={2} pt={2}>
                            <Typography>
                                Are you sure you want to delete {deleteConfirmRowItem ? `"${deleteConfirmRowItem.title}"` : ''} client ?
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleDeleteRowItem(deleteConfirmRowItem)}} autoFocus variant="text" color="error">Delete</Button>
                    <Button onClick={handleDeleteConfirmClose} variant="text" color="primary">Cancell</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={Boolean(resetStatsConfirmRowItem)} onClose={handleResetClientStatsConfirm}>
                <DialogTitle justifyContent="space-between">
                    Reset stats reporting for {resetStatsConfirmRowItem ? `"${resetStatsConfirmRowItem.title}"` : ''} ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        <Box m={2} pt={2}>
                            <Typography>
                                This action force shortener to resend the url open webhooks for all the opened short urls
                                generated by {resetStatsConfirmRowItem ? `"${resetStatsConfirmRowItem.title}"` : ''} client.&nbsp;
                                It could cause additional load for services receiving url open webhooks.
                            </Typography>
                            <Typography>
                                <strong>Are you sure you want to resend all the webhooks?</strong>
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleStatsResetRowItem(resetStatsConfirmRowItem)}} autoFocus variant="text" color="error">Reset reporting</Button>
                    <Button onClick={handleResetClientStatsConfirm} variant="text" color="primary">Cancell</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


