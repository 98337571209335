import {Typography, Stack, styled, Tooltip, IconButton} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const LonTextContainer = styled(Typography)(() => ({
    maxWidth: "100px",
    lineHeight: "57px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
}));

export default function ApiKeyContainer({value}){
    return <Stack direction="row" spacing={1}>
        <LonTextContainer variant="string">
            {value}
        </LonTextContainer>
        <Tooltip title="Copy to clipboard">
            <IconButton onClick={() => {navigator.clipboard.writeText(value)}}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    </Stack>
}
