import {Helmet} from "react-helmet-async";
import {
    Box,
    Button,
    Card, Checkbox,
    CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem,
    Paper, Popover,
    Stack,
    Table, TableBody, TableCell,
    TableContainer, TableHead,
    TableRow,
    Typography,
    Alert, TablePagination
} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Scrollbar from "../../components/scrollbar";
import {deleteBaseUrl, getMyBaseUrls} from "../../api/userApi";
import Label from '../../components/label';
import Iconify from "../../components/iconify";

export default function BaseUrlsListPage() {
    const navigate = useNavigate();

    const [loadingDataGrid, setLoadingDataGrid] = useState(true);
    const [dataGridData, setDataGridData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState(null);
    const [rowActionsItem, setRowActionsItem] = useState(null);
    const [deleteConfirmRowItem, setDeleteConfirmRowItem] = useState(null);

    const loadDataGrid = () => {
        setLoadingDataGrid(true);
        getMyBaseUrls(page+1, rowsPerPage).then((response) => {
            setDataGridData(response.data.items);
            setTotalRows(response.data.pagination.totalCount);
            setLoadingDataGrid(false);
        });
    }

    const handleCloseRowActions = () => {
        setRowActionsAnchorEl(null);
        setRowActionsItem(null);
    }

    const handleDeleteRowConfirm = () => {
        setDeleteConfirmRowItem(rowActionsItem);
        handleCloseRowActions();
    }
    const handleDeleteConfirmClose = () => {
        setDeleteConfirmRowItem(null);
    }

    const handleDeleteRowItem = (item) => {
        handleDeleteConfirmClose();
        setLoadingDataGrid(true);
        deleteBaseUrl(item.id)
            .then(()=>{
                loadDataGrid();
            })
            .catch((error) => {
                setLoadingDataGrid(false);
                console.log(error);
            })
    }

    useEffect(() => {
        loadDataGrid();
    }, [page, rowsPerPage]);

    return (
        <>
            <Helmet>
                <title> My Base Urls </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        My Base Urls
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={()=>{navigate('new', { replace: true })}}>
                        Register your baseurl
                    </Button>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Base url</TableCell>
                                        <TableCell>Generated urls</TableCell>
                                        <TableCell align="center" sx={{ minWidth: '3rem' }}>Public</TableCell>
                                        <TableCell align="center" sx={{ minWidth: '3rem' }}>Verified</TableCell>
                                        <TableCell align="center" sx={{ minWidth: '3rem' }}>Active</TableCell>
                                        <TableCell align="center">&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loadingDataGrid ? (<TableRow hover >
                                        <TableCell align="center">
                                            <Paper sx={{ textAlign: 'center', p: '4rem' }}>
                                                <CircularProgress size="5rem" color="secondary" />
                                                <Typography variant="h6" paragraph color="secondary" >
                                                    Loading
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>) : dataGridData.map((dataGridItem)=>{
                                        const { id, domainUrl, shared, enabled, verified, sequence } = dataGridItem;
                                        return <TableRow hover key={id} tabIndex={-1}>
                                            <TableCell align="left">{domainUrl}</TableCell>
                                            <TableCell align="left">{new Intl.NumberFormat().format(sequence)}</TableCell>
                                            <TableCell align="center"><Label color={!shared ? 'success' : 'warning'}>{shared ? 'Public' : 'Private'}</Label></TableCell>
                                            <TableCell align="center"><Label color={verified ? 'success' : 'error'}>{verified ? 'Verified' : 'Pending'}</Label></TableCell>
                                            <TableCell align="center"><Checkbox checked={enabled && verified} disabled={!verified} /></TableCell>
                                            <TableCell align="right">
                                                <IconButton size="large" color="inherit" onClick={(event)=>{setRowActionsAnchorEl(event.currentTarget);setRowActionsItem(dataGridItem)}}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    } )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {setPage(0);setRowsPerPage(parseInt(event.target.value, 10))}}
                    />
                </Card>
            </Container>
            <Popover
                open={Boolean(rowActionsAnchorEl)}
                anchorEl={rowActionsAnchorEl}
                onClose={handleCloseRowActions}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteRowConfirm}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} /> Delete
                </MenuItem>
            </Popover>
            <Dialog open={Boolean(deleteConfirmRowItem)} onClose={handleDeleteConfirmClose}>
                <DialogTitle justifyContent="space-between">
                    Delete {deleteConfirmRowItem ? `"${deleteConfirmRowItem.domainUrl}"` : ''} ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        <Alert severity="warning">
                            <Typography color="error"><strong >This action can not be undone.</strong></Typography>
                            <Typography>Base url itself and all shortened urls would be removed from database.</Typography>
                        </Alert>
                        <Box m={2} pt={2}>
                            <Typography variant="subtitle1">
                                Are you sure you want to delete {deleteConfirmRowItem ? `"${deleteConfirmRowItem.domainUrl}"` : ''} baseurl ?
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleDeleteRowItem(deleteConfirmRowItem)}} autoFocus variant="text" color="error">Delete</Button>
                    <Button onClick={handleDeleteConfirmClose} variant="text" color="primary">Cancell</Button>
                </DialogActions>
            </Dialog>
        </>);
}
