import axios from "axios";
import {getAuthentication} from "./userApi";

const instance = axios.create({
    baseURL: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:8080/' : '',
});

instance.interceptors.request.use(
    async (config) => {
        const requestConfig = { headers: {}, ...config };
        const token = getAuthentication();
        if (token) {
            requestConfig.headers[token.header] = `Bearer ${token.token}`;
        }
        return requestConfig;
    },
    (error) => Promise.reject(error)
);

export default instance;