import {Autocomplete, Avatar, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {adminFetchUserPicker} from "../../api/adminApi";

let filterValueChars = 0;

export default function AdminUserPicker({value, onChange}) {

    const [userPickerOptions, setUserPickerOptions] = useState(value ? [value] : []);
    const [userPickerInputValue, setUserPickerInputValue] = useState('');

    const fetchOptions = () => {
        adminFetchUserPicker(30, userPickerInputValue)
            .then((response) => {
                setUserPickerOptions(response.data.items);
            })
    }

    useEffect(() => {
        if(filterValueChars > 0){

            setTimeout(()=>{
                console.log(filterValueChars);
                filterValueChars -= 1;
                if(filterValueChars === 0){
                    fetchOptions();
                }
            }, 500);
        } else {
            fetchOptions();
        }
    }, [userPickerInputValue]);


    return (
        <Autocomplete
            options={userPickerOptions}
            filterOptions={(x) => x}
            filterSelectedOptions
            onInputChange={(event, newInputValue) => {
                filterValueChars += 1;
                setUserPickerInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Select owning user" fullWidth />
            )}
            value={value}
            onChange={(event, newValue) => {
                setUserPickerOptions(newValue ? [newValue, ...userPickerOptions] : userPickerOptions);
                onChange(newValue);
            }}
            getOptionDisabled={(option) => !option.enabled}
            getOptionLabel={(option) => option.username}
            renderOption={(props, option) => {
                return <li {...props}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={option.username} src={option.avatar} />
                        <Typography variant="subtitle1" noWrap>
                            {option.username}
                        </Typography>
                        <Typography variant="inherit" color="grey" noWrap>
                            &lt;{option.email}&gt;
                        </Typography>
                    </Stack>
                </li>
            }}
        />
    )
}