import {Helmet} from "react-helmet-async";
import {Card, Container, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import {BaseUrlForm} from "../../sections/base-url/admin-form";
import {adminCreateBaseUrl} from "../../api/adminApi";


const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function BaseUrlNewPage() {
    const navigate = useNavigate();
    const returnPath = '/admin/baseurls';

    const handleFormSubmit = (data) => {
        const request = adminCreateBaseUrl(data)
        request
            .then(() => {navigate(returnPath, { replace: true });});

        return request;
    };

    return (
        <>
            <Helmet>
                <title> New Base Url </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Base Url
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <StyledContent>
                            <BaseUrlForm actionLabel="Create" returnUrl={returnPath} formSubmitCallback={handleFormSubmit} />
                        </StyledContent>
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}