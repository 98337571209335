import {
    Button, Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function BaseUrlForm({modelData, formSubmitCallback, actionLabel, returnUrl}) {
    const navigate = useNavigate();
    const [domain, setDomain] = useState(modelData ? modelData.domain : '');
    const [baseurl, setBaseurl] = useState(modelData ? modelData.baseurl : '/');
    const [httpsEnabled, setHttpsEnabled] = useState(modelData ? modelData.httpsEnabled : true);
    const [shared, setShared] = useState(modelData ? modelData.shared : false);
    const [enabled, setEnabled] = useState(modelData ?modelData.enabled : false);
    const [verified, setVerified] = useState(modelData ?modelData.verified : false);

    const [isProcessing, setIsProcessing] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleSubmit = (event) => {
        event.preventDefault();

        setIsProcessing(true);
        formSubmitCallback({
            domain,
            baseurl,
            httpsEnabled,
            shared,
            enabled,
            verified
        }).then(() => {
            setValidationErrors({});
            setIsProcessing(false);
        })
        .catch((error) => {
            if(error.response.data.errors){
                setValidationErrors(error.response.data.errors);
            }
            setIsProcessing(false);
        });
    }
    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                <TextField
                    required
                    name="domain"
                    label="Domain"
                    value={domain}
                    onChange={(event) => setDomain(event.target.value)}
                    helperText={validationErrors.domain ? validationErrors.domain : ''}
                    error={Boolean(validationErrors.domain)}
                />
                <TextField
                    required
                    name="baseurl"
                    label="Base path"
                    value={baseurl}
                    onChange={(event) => setBaseurl(event.target.value)}
                    helperText={validationErrors.baseurl ? validationErrors.baseurl : ''}
                    error={Boolean(validationErrors.baseurl)}
                />
                <FormControl>
                    <FormLabel id="https-enabled-switch-label">Protocol</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="https-enabled-switch"
                        name="httpsEnabled"
                    >
                        <FormControlLabel value="true" control={<Radio checked={httpsEnabled} />} label="https" onClick={()=>{setHttpsEnabled(true)}} />
                        <FormControlLabel value="false" control={<Radio checked={!httpsEnabled} />} label="http" onClick={()=>{setHttpsEnabled(false)}} />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel id="shared-switch-label">Visibility</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="shared-switch"
                        name="shared"
                    >
                        <FormControlLabel value="true" control={<Radio checked={shared} />} label="Public use" onClick={()=>{setShared(true)}} />
                        <FormControlLabel value="false" control={<Radio checked={!shared} />} label="Private" onClick={()=>{setShared(false)}} />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>Is Verified</FormLabel>
                    <FormControlLabel control={<Checkbox checked={verified} onChange={() => {setVerified(!verified)}} />} label="Is Verified" />
                </FormControl>
                <FormControl>
                    <FormLabel>Is Enabled</FormLabel>
                    <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {setEnabled(!enabled)}} />} label="Is Enabled" />
                </FormControl>

                <Stack spacing={1} direction="row" justifyContent="right">
                    {!isProcessing ? <Button type="submit" variant="contained">{actionLabel}</Button> : <Button type="button" disabled variant="contained"><CircularProgress size="1rem" color="inherit" /> &nbsp; {actionLabel}</Button>}
                    <Button variant="text" color="secondary" onClick={(event) => {event.preventDefault();navigate(returnUrl);}}>Cancel</Button>
                </Stack>
            </Stack>
        </form>
    );
}