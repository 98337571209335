import {useState} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
// components
import { forgotPassword } from "../../api/userApi";
import ProcessingButton from "../../components/custom_components/processing-button/ProcessingButton";
import AlertMessage from "../../components/custom_components/alerts/AlertMessage";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export default function PasswordRecoveryForm() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [show, setShow] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setReShowPassword] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsProcessing(true);
        forgotPassword({
            password,
            rePassword,
            token: searchParams}
        ).then((response) => {
            setIsProcessing(false);
            setShow(null);
            console.log(response);
            navigate('/login', {replace: true});
        }).catch((error) => {
            console.log(error);
            setShow('Something went wrong. Please, try again');
            setPassword('');
            setRePassword('');
            setIsProcessing(false);
        });

    };

    return (
        <form onSubmit={handleSubmit}>
            <AlertMessage message={show} severity="error"/>
            <Stack spacing={3} sx={{mb: 3}}>
                <TextField
                    name="password"
                    label="Set new password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                            </IconButton>
                        </InputAdornment>),
                    }}
                />

                <TextField
                    name="rePassword"
                    label="Repeat your password"
                    type={showRePassword ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <IconButton onClick={() => setReShowPassword(!showRePassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                            </IconButton>
                        </InputAdornment>),
                    }}
                />
            </Stack>
            <ProcessingButton processing={isProcessing} title="Change password"/>
        </form>
    );
}
