import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Container } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function Home() {
    return (<>
        <Helmet>
            <title> Dashboard | Minimal UI </title>
        </Helmet>
        <Container>
            <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
                <Typography variant="h3" paragraph>
                    Shortener, under construction!
                </Typography>

                <Typography sx={{ color: 'text.secondary' }}>
                    Sorry, we couldn’t shortener ui is not fully constructed yet
                </Typography>
            </StyledContent>
        </Container>

    </>);
}