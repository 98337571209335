import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress, FormControl,
    Grid,
    Stack,
    TextField,
} from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import AvatarComponent from "../../../components/custom_components/avatar";

EditingMyInfo.prototype = {
    userInfo: PropTypes.object.isRequired,
    formSubmitCallback: PropTypes.func.isRequired,
}

export default function EditingMyInfo({userInfo, formSubmitCallback}) {

    const [username, setUsername] = useState(userInfo.username || '');
    const [email, setEmail] = useState(userInfo.email || '');
    const [avatar, setAvatar] = useState(userInfo.avatar || '');
    const [validationErrors, setValidationErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);

    const resetForm = (event) => {
        event.preventDefault();
        setUsername(userInfo.username);
        setEmail(userInfo.email);
        setAvatar(userInfo.avatar);
        setValidationErrors({});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            username,
            email,
            avatar,
        };
        setIsProcessing(true);
        formSubmitCallback(userData)
        // TODO remove next line and uncomment below
        setTimeout(() => setIsProcessing(false), 2000);
            // .then(() => {
            //     setValidationErrors({});
            //     setIsProcessing(false);
            // })
            // .catch((error) => {
            //     if(error.response.data.errors){
            //         setValidationErrors(error.response.data.errors);
            //     }
            //     setIsProcessing(false);
            // });
    }

    return (
        <Grid item xs={12} md={6} lg={8}>
            <Card sx={{mb: 3}}>
                <CardHeader title='Edit your personal info'/>
            </Card>
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <TextField
                                required
                                name="username"
                                label="User name"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                helperText={validationErrors.username ? validationErrors.username : ''}
                                error={Boolean(validationErrors.username)}
                            />

                            <TextField
                                required
                                name="email"
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                helperText={validationErrors.email ? validationErrors.email : ''}
                                error={Boolean(validationErrors.email)}
                            />

                            <FormControl sx={{ width: 'fit-content' }}>
                                <AvatarComponent avatar={avatar} setAvatar={setAvatar}/>
                            </FormControl>

                            <Stack spacing={1} direction="row" justifyContent="right">
                                {!isProcessing ? <Button type="submit" variant="contained">Update</Button> : <Button type="button" disabled variant="contained"><CircularProgress size="1rem" color="inherit" /> &nbsp; Update</Button>}
                                <Button variant="text" color="secondary" onClick={resetForm}>Reset form</Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>

        </Grid>
    )
}