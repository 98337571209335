import { Navigate, useRoutes } from 'react-router-dom';
import {useContext} from "react";
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import UsersPage from './pages/admin/UsersPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import Home from "./pages/Home";
import ProtectedRoute from "./components/protected-route";
import UserNewPage from "./pages/admin/UserNewPage";
import UserEditPage from "./pages/admin/UserEditPage";
import BaseUrlsPage from "./pages/admin/BaseUrlsPage";
import BaseUrlNewPage from "./pages/admin/BaseUrlNewPage";
import BaseUrlEditPage from "./pages/admin/BaseUrlEditPage";
import ClientsPage from "./pages/admin/ClientsPage";
import ClientEditPage from "./pages/admin/ClientEditPage";
import ClientNewPage from "./pages/admin/ClientNewPage";
import {UserContext} from "./contexts/contexts";
import AccountEditPage from "./pages/profile/AccountEditPage";
import BaseUrlsListPage from "./pages/baseurls/BaseUrlsListPage";
import BaseUrlRegisterPage from "./pages/baseurls/BaseUrlRegisterPage";
import { LoginForm, ForgotPasswordForm, PasswordRecoveryForm } from "./sections/auth";

// ----------------------------------------------------------------------

export default function Router() {
  const account = useContext(UserContext);

  return useRoutes([
    {
      path: '/dashboard',
      element: <ProtectedRoute account={account} allowedRoles={['ROLE_USER', 'ROLE_ADMIN']}><DashboardLayout /></ProtectedRoute>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app-old', element: <DashboardAppPage /> },
        { path: 'app', element: <Home /> },
        { path: 'bases', element: <BaseUrlsListPage /> },
        { path: 'bases/new', element: <BaseUrlRegisterPage /> },
        { path: 'clients', element: <ClientsPage /> }
      ],
    },
    {
      path: '/account',
      element: <ProtectedRoute account={account} allowedRoles={['ROLE_USER', 'ROLE_ADMIN']}><DashboardLayout /></ProtectedRoute>,
      children: [
        { element: <Navigate to="/account/overview" />, index: true },
        { path: 'overview', element: <AccountEditPage /> },
        { path: 'edit', element: <AccountEditPage /> },
        { path: 'change-password', element: <AccountEditPage /> }
      ]
    },
    {
      path: '/admin',
      element: <ProtectedRoute account={account} allowedRoles={['ROLE_ADMIN']}><DashboardLayout /></ProtectedRoute>,
      children: [
        { element: <Navigate to="/admin/users" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'users/new', element: <UserNewPage /> },
        { path: 'users/:id/edit', element: <UserEditPage /> },
        { path: 'baseurls', element: <BaseUrlsPage /> },
        { path: 'baseurls/new', element: <BaseUrlNewPage /> },
        { path: 'baseurls/:id/edit', element: <BaseUrlEditPage /> },
        { path: 'clients', element: <ClientsPage /> },
        { path: 'clients/new', element: <ClientNewPage /> },
        { path: 'clients/:id/edit', element: <ClientEditPage /> },
      ],
    },
    {
      path: 'login',
      element: (account === null ? <LoginPage formTitle="Sign in to Shortener" childComponent={<LoginForm />} /> : <Navigate to="/404" replace />),
    },
    {
      path: 'forgot-password',
      element: <LoginPage formTitle="Forgot password" childComponent={<ForgotPasswordForm/>} />,
    },
    {
      path: 'password-recovery',
      element: <LoginPage formTitle="Password recovery" childComponent={<PasswordRecoveryForm/>} />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '/', element: (account === null ? <Home /> : <Navigate to="/dashboard" />), index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
