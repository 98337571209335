import {Helmet} from "react-helmet-async";
import {Card, Container, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import BaseUrlRegisterForm from "../../sections/base-url/register-form/BaseUrlRegisterForm";


const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function BaseUrlRegisterPage() {
    return (
        <>
            <Helmet>
                <title> New Base Url </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Register Base Url
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <StyledContent>
                            <BaseUrlRegisterForm />
                        </StyledContent>
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}