import {useEffect, useState} from "react";
import {Autocomplete, Avatar, Stack, TextField, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {adminFetchBaseUrlPicker} from "../../api/adminApi";


let filterValueChars = 0;
export default function AdminBaseurlPicker({value, onChange}) {
    const [options, setOptions] = useState(value ? [value] : []);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchOptions = () => {
        setLoading(true)
        adminFetchBaseUrlPicker(30, inputValue)
            .then((response) => {
                setOptions(response.data.items);
                setLoading(false);
            })
    }

    useEffect(() => {
        if(filterValueChars > 0){
            setTimeout(()=>{
                console.log(filterValueChars);
                filterValueChars -= 1;
                if(filterValueChars === 0){
                    fetchOptions();
                }
            }, 500);
        } else {
            fetchOptions();
        }
    }, [inputValue]);

    const addedBaseIds = value.map((item) => item.id);
    const filterOptions = (items) => {
        const filtered = [];
        for(let i=0 ; i<items.length; i+=1){
            if(addedBaseIds.indexOf(items[i].id) === -1){
                filtered.push(items[i]);
            }
        }

        return filtered;
    }

    return (
        <Autocomplete
            multiple
            options={options}
            filterOptions={filterOptions}
            filterSelectedOptions
            onInputChange={(event, newInputValue) => {
                filterValueChars += 1;
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Select base urls" fullWidth />
            )}
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                onChange(newValue);
            }}
            loading={loading}
            getOptionDisabled={(option) => !option.enabled}
            getOptionLabel={(option) => option.domainUrl}
            renderOption={(props, option) => {
                return <li {...props}>
                    <Stack direction="row" spacing={2} sx={{display: 'flex', justifyContent:"space-between", width: "100%"}}>
                        <Typography variant="subtitle1">{option.domainUrl}</Typography>
                        {option.owner ? (
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={option.owner.username} src={option.owner.avatar} sx={{ height: '2rem', width: '2rem' }} />
                                <Typography variant="inherit" color="grey" noWrap>
                                    {option.owner.username} &lt;{option.owner.email}&gt;
                                </Typography>
                            </Stack>
                        ) : ''}
                    </Stack>
                </li>
            }}
        />
    )
}

AdminBaseurlPicker.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};