import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Card, Container, Stack, Typography, styled} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import ClientForm from "../../sections/client/admin-form/ClientForm";
import {adminCreateClients} from "../../api/adminApi";

const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function ClientNewPage() {
    const navigate = useNavigate();
    const returnPath = '/admin/clients';

    const handleFormSubmit = (data) => {
        const request = adminCreateClients(data)
        request
            .then(() => {navigate(returnPath, { replace: true });});

        return request;
    };

    return (
        <>
            <Helmet>
                <title> New api client </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New api client
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <StyledContent>
                            <ClientForm actionLabel="Create" returnUrl={returnPath} formSubmitCallback={handleFormSubmit} showKeys={false} />
                        </StyledContent>
                    </Scrollbar>
                </Card>
            </Container>
        </>
    )
}