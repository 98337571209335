import PropTypes from 'prop-types';
// @mui
import { Avatar, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { getStylesWidth } from "../../../utils/cssStyles";
import './Avatar.css';


// ----------------------------------------------------------------------
const avatars = [
  '/assets/images/avatars/avatar_1.jpg',
  '/assets/images/avatars/avatar_2.jpg',
  '/assets/images/avatars/avatar_3.jpg',
  '/assets/images/avatars/avatar_4.jpg',
  '/assets/images/avatars/avatar_5.jpg',
  '/assets/images/avatars/avatar_6.jpg',
  '/assets/images/avatars/avatar_7.jpg',
  '/assets/images/avatars/avatar_8.jpg',
  '/assets/images/avatars/avatar_9.jpg',
  '/assets/images/avatars/avatar_10.jpg',
  '/assets/images/avatars/avatar_11.jpg',
  '/assets/images/avatars/avatar_12.jpg',
  '/assets/images/avatars/avatar_13.jpg',
  '/assets/images/avatars/avatar_14.jpg',
  '/assets/images/avatars/avatar_15.jpg',
  '/assets/images/avatars/avatar_16.jpg',
  '/assets/images/avatars/avatar_17.jpg',
  '/assets/images/avatars/avatar_18.jpg',
  '/assets/images/avatars/avatar_19.jpg',
  '/assets/images/avatars/avatar_20.jpg',
  '/assets/images/avatars/avatar_21.jpg',
  '/assets/images/avatars/avatar_22.jpg',
  '/assets/images/avatars/avatar_23.jpg',
  '/assets/images/avatars/avatar_24.jpg',
  '/assets/images/avatars/avatar_default.jpg'
]

const AvatarComponent = ({avatar, setAvatar}) => {
  const theme = useTheme();

  return (
      <>
        <InputLabel id="avatar-label">Avatar</InputLabel>
        <Select
            labelId="avatar-label"
            id="avatar-select"
            label="Avatar"
            onChange={(event) => {
              setAvatar(event.target.value)
            }}
            value={avatar}
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
            }}
        >
          {avatars.map((avatarUrl) => (
              <MenuItem
                  key={avatarUrl}
                  value={avatarUrl}
                  style={getStylesWidth(avatarUrl, avatars, theme)}
              >
                <Avatar src={avatarUrl} sx={{height: '64px', width: '64px'}}/>
              </MenuItem>
          ))}
        </Select>
      </>
  )
}

AvatarComponent.propTypes = {
  avatar: PropTypes.string,
  setAvatar: PropTypes.func,
};

export default AvatarComponent;
