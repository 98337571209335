import {Navigate, Outlet} from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedRoute = ({
        account,
        allowedRoles,
        redirectPath = '/404',
        children,
    }) => {
    // console.log(account);
    if(!!account){
        for(let i=0; i<allowedRoles.length; i+=1){
            if(account.authorities.indexOf(allowedRoles[i]) !== -1){
                return (children || <Outlet />);
            }
        }
    }
    return (<Navigate to={redirectPath} replace />);
}

ProtectedRoute.propTypes = {
    redirectPath: PropTypes.string,
    account: PropTypes.object,
    allowedRoles: PropTypes.array.isRequired,
    children: PropTypes.object.isRequired,
};

export default ProtectedRoute;
