import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider } from '@mui/material';
// components
import Logo from '../components/logo';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function LoginPage({formTitle, childComponent}) {
  return (
    <>
      <Helmet>
        <title> Login | Shortener </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
                {formTitle}
            </Typography>

            <Divider sx={{ my: 3 }} />

              {childComponent}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

LoginPage.prototype = {
    childComponent: PropTypes.node,
    formTitle: PropTypes.string,
}

export default LoginPage;