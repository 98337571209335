import {useState} from 'react';
// @mui
import { Stack, TextField } from '@mui/material';
// components
import { forgotPassword } from "../../api/userApi";
import ProcessingButton from "../../components/custom_components/processing-button/ProcessingButton";
import AlertMessage from "../../components/custom_components/alerts/AlertMessage";

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
    const [username, setUsername] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [show, setShow] = useState('');
    const [severity, setSeverity] = useState('success');

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsProcessing(true);
        forgotPassword(
            username,
        ).then((response) => {
            setIsProcessing(false);
            setSeverity('success');
            setShow('Check your associated email');
            console.log(response);
        }).catch((error) => {
            console.log(error);
            setSeverity('error')
            setShow('Something went wrong. Please, try again');
            setIsProcessing(false);
        });

    };

    return (
        <form onSubmit={handleSubmit}>
            <AlertMessage message={show} severity={severity}/>
            <Stack sx={{mb: 3}}>
                <TextField name="username" label="User name" onChange={(event) => setUsername(event.target.value)} />
            </Stack>
            <ProcessingButton processing={isProcessing} title="Get recovery link"/>
        </form>
    );
}
