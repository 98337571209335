import {createContext, useState} from "react";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import {getAuthenticatedUser, getAuthentication, loadProfile, storeUserData} from "./api/userApi";
import{UserContext, SetUserContext} from './contexts/contexts'

let activeTimerId;
// ----------------------------------------------------------------------

export default function App() {
    const [authenticatedUser, setAuthenticatedUser] = useState(getAuthenticatedUser());

    const reloadCurrentUser = () => {
        clearTimeout(activeTimerId);
        activeTimerId = setTimeout(()=>{
            console.info("Refreshing authenticated user data");
            if(getAuthentication() !== null){
                loadProfile()
                    .then((response) => {
                        storeUserData(response.data);
                        setAuthenticatedUser(getAuthenticatedUser());
                    });
            }
            reloadCurrentUser();
        }, 300000)
    }
    reloadCurrentUser();

    return (
      <UserContext.Provider value={authenticatedUser}>
          <SetUserContext.Provider value={setAuthenticatedUser}>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
            </ThemeProvider>
          </SetUserContext.Provider>
      </UserContext.Provider>
    );
}
