import {useContext, useState} from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Button} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {clearAuthentication} from "../../api/userApi";
import {SetUserContext, UserContext} from "../../contexts/contexts";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Profile',
        link: '/account'
    },
    // {
    //     label: 'Edit account',
    //     link: '/account/edit'
    // },
    // {
    //     label: 'Change password',
    //     link: '/account/change-password'
    // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const account = useContext(UserContext);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const userStateSetter = useContext(SetUserContext);

  const handleLogout = () => {
      handleClose();
      clearAuthentication();
      userStateSetter(null);
      navigate(`/`);
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const goToLogin = (event) => {
    event.preventDefault();
    navigate(`/login`);
  }

  const handleClose = () => {
    setOpen(null);
  };

  return account
      ? (
        <>
          <IconButton
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            <Avatar alt="photoURL" src={account.avatar} />
          </IconButton>
          <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 0,
                  mt: 1.5,
                  ml: 0.75,
                  width: 180,
                  '& .MuiMenuItem-root': {
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {account.username}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {account.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option) => (
                  <MenuItem key={option.label} onClick={()=>{handleClose();navigate(option.link)}}>
                    {option.label}
                  </MenuItem>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={() => {handleLogout();}} sx={{ m: 1 }}>
              Logout
            </MenuItem>
          </Popover>
        </>
      )
      : (
          <Button color="inherit" onClick={goToLogin}>Login</Button>
      );
}
