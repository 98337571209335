import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";


const ProcessingButton = (
    {
        title,
        processing = false,
        fullWidth = true,
        size = 'large',
        variant = 'contained',
    }) => (
        processing ? (<Button fullWidth={fullWidth} size={size} type="button" disabled="disabled" variant={variant}><CircularProgress color="inherit" /></Button>)
    : <Button fullWidth={fullWidth} size={size} type="submit" variant={variant}>{title}</Button>)

ProcessingButton.propTypes = {
    processing: PropTypes.bool,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
};

export default ProcessingButton;