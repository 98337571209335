// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const adminNavConfig = [
    {
        title: 'users',
        path: '/admin/users',
        icon: icon('ic_user'),
    },
    {
        title: 'shortener base urls',
        path: '/admin/baseurls',
        icon: icon('ic_blog'),
    },
    {
        title: 'shortener clients',
        path: '/admin/clients',
        icon: icon('ic_lock'),
    }
];

export default adminNavConfig;
