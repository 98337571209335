import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Grid, IconButton, InputAdornment,
    Stack,
    TextField,
} from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import Iconify from "../../../components/iconify";

function ChangeMyPassword({formSubmitCallback}) {
    const [showPassword, setShowPassword] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const resetForm = (event) => {
        event.preventDefault();
        setPassword('');
        setRePassword('');
        setShowPassword(false);
        setValidationErrors({});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            password,
            rePassword
        };
        setIsProcessing(true);
        formSubmitCallback(userData)
        // TODO remove next line and uncomment below
        setTimeout(() => setIsProcessing(false), 2000);
            // .then(() => {
            //     setValidationErrors({});
            //     setIsProcessing(false);
            // })
            // .catch((error) => {
            //     if(error.response.data.errors){
            //         setValidationErrors(error.response.data.errors);
            //     }
            //     setIsProcessing(false);
            // });
    }

    return (
        <Grid item xs={12} md={6} lg={8}>
            <Card sx={{mb: 3}}>
                <CardHeader title='Change your password'/>
            </Card>
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <TextField
                                name="password"
                                label="Password"
                                autoComplete='new-password'
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => setPassword(event.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={validationErrors.password ? validationErrors.password : ''}
                                error={Boolean(validationErrors.password)}
                            />

                            <TextField
                                name="rePassword"
                                label="Password Confirm"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => setRePassword(event.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={validationErrors.rePassword || ''}
                                error={Boolean(validationErrors.rePassword)}
                                autoComplete='new-password'
                            />

                            <Stack spacing={1} direction="row" justifyContent="right">
                                {!isProcessing ? <Button type="submit" variant="contained">Change password</Button> : <Button type="button" disabled variant="contained"><CircularProgress size="1rem" color="inherit" /> &nbsp; Update</Button>}
                                <Button variant="text" color="secondary" onClick={resetForm}>Clear form</Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardContent>
            </Card>

        </Grid>
    )
}

ChangeMyPassword.prototype = {
    formSubmitCallback: PropTypes.func.isRequired,
}

export default ChangeMyPassword;