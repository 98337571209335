import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../iconify';


// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

DataGridToolbar.propTypes = {
  filterValue: PropTypes.string,
  placeholder: PropTypes.string,
  onFilterChange: PropTypes.func
};

let filterValueChars = 0;

export default function DataGridToolbar({ filterValue, onFilterChange, placeholder }) {
  const [currentFilterValue, setCurrentFilterValue] = useState(filterValue);

  const handleFilterValueChange = (event) => {
    setCurrentFilterValue(event.target.value);
    filterValueChars += 1;
  }

  useEffect(() => {
    if(filterValueChars > 0){
      setTimeout(()=>{
        filterValueChars -= 1;
        if(filterValueChars === 0){
          onFilterChange(currentFilterValue);
        }
      }, 1000);
    }
  }, [currentFilterValue]);

  return (
    <StyledRoot>
      <StyledSearch
        value={currentFilterValue}
        onChange={handleFilterValueChange}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
      <Tooltip title="Filter list">
        <IconButton>
          <Iconify icon="ic:round-filter-list" />
        </IconButton>
      </Tooltip>
    </StyledRoot>
  );
}
