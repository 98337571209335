import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Card, Container, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";

import Scrollbar from "../../components/scrollbar/Scrollbar";
import {adminGetBaseUrlDetails, adminUpdateBaseUrl} from "../../api/adminApi";
import {BaseUrlForm} from "../../sections/base-url/admin-form";


const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function BaseUrlEditPage() {
    const navigate = useNavigate();
    const returnPath = '/admin/baseurls';

    const {id} = useParams();
    const [loadingModel, setLoadingModel] = useState(true);
    const [modifyModel, setModifyModel] = useState(null);


    useEffect(() => {
        setLoadingModel(true);
        adminGetBaseUrlDetails(id)
            .then((response) => {
                setModifyModel(response.data);
                setLoadingModel(false);
            });
    }, []);

    const handleFormSubmit = (data) => {
        const request = adminUpdateBaseUrl(id, data)
        request
            .then(() => {navigate(returnPath, { replace: true });});

        return request;
    };

    return (
        <>
            <Helmet>
                <title> Modify Base Url </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Modify {modifyModel && modifyModel.domainUrl}
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        {
                            loadingModel ? '' : (
                                <StyledContent>
                                    <BaseUrlForm actionLabel="Update" returnUrl={returnPath} modelData={modifyModel} formSubmitCallback={handleFormSubmit} />
                                </StyledContent>
                            )
                        }
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}