import {
    Box, Button, Checkbox,
    Chip, CircularProgress, FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,
    Stack,
    TextField
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Iconify from "../../../components/iconify";
import { getStylesWidth } from "../../../utils/cssStyles";
import AvatarComponent from "../../../components/custom_components/avatar";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const userRoles = [
    'ROLE_USER',
    'ROLE_ADMIN',
    'ROLE_ACTUATOR',
    'ROLE_APIDOC'
];


export default function UserForm({userData, formSubmitCallback, actionLabel, rePasswordEnable}) {
    const theme = useTheme();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [username, setUsername] = useState(userData ? userData.username : '');
    const [email, setEmail] = useState(userData ? userData.email : '');
    const [avatar, setAvatar] = useState(userData ? userData.avatar : '');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [enabled, setEnabled] = useState(userData ?userData.enabled : false);
    const [authorities, setAuthorities] = useState(userData ? userData.authorities : ['ROLE_USER']);
    const [validationErrors, setValidationErrors] = useState({});

    const handleAuthoritiesChange = (event) =>{
        const {
            target: { value },
        } = event;
        setAuthorities(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            username,
            email,
            password,
            rePassword,
            avatar,
            enabled,
            authorities
        };
        setIsProcessing(true);
        formSubmitCallback(userData)
            .then(() => {
                setValidationErrors({});
                setIsProcessing(false);
            })
            .catch((error) => {
                if(error.response.data.errors){
                    setValidationErrors(error.response.data.errors);
                }
                setIsProcessing(false);
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                <TextField
                    required
                    name="username"
                    label="User name"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    helperText={validationErrors.username ? validationErrors.username : ''}
                    error={Boolean(validationErrors.username)}
                />

                <TextField
                    required
                    name="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    helperText={validationErrors.email ? validationErrors.email : ''}
                    error={Boolean(validationErrors.email)}
                />

                <TextField
                    name="password"
                    label="Password"
                    autoComplete='new-password'
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    helperText={validationErrors.password ? validationErrors.password : ''}
                    error={Boolean(validationErrors.password)}
                />
                {rePasswordEnable && (
                    <TextField
                        name="rePassword"
                        label="Password Confirm"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(event) => setRePassword(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        helperText={validationErrors.rePassword || ''}
                        error={Boolean(validationErrors.rePassword)}
                        autoComplete='new-password'
                    />
                )}

                <FormControl>
                    <AvatarComponent avatar={avatar} setAvatar={setAvatar} theme={theme}/>
                </FormControl>

                <FormControl>
                    <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={authorities}
                        onChange={handleAuthoritiesChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        helperText={validationErrors.authorities || ''}
                        error={Boolean(validationErrors.authorities)}
                    >
                        {userRoles.map((role) => (
                            <MenuItem
                                key={role}
                                value={role}
                                style={getStylesWidth(role, authorities, theme)}
                            >
                                {role}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Is Enabled</FormLabel>
                    <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {setEnabled(!enabled)}} />} label="Is Enabled" />
                </FormControl>

                <Stack spacing={1} direction="row" justifyContent="right">
                    {!isProcessing ? <Button type="submit" variant="contained">{actionLabel}</Button> : <Button type="button" disabled variant="contained"><CircularProgress size="1rem" color="inherit" /> &nbsp; {actionLabel}</Button>}
                    <Button variant="text" color="secondary" onClick={(event) => {event.preventDefault();navigate("/admin/users");}}>Cancel</Button>
                </Stack>
            </Stack>
        </form>
    );
}

UserForm.propTypes = {
    userData: PropTypes.object,
    formSubmitCallback: PropTypes.func.isRequired,
    actionLabel: PropTypes.string.isRequired,
    rePasswordEnable: PropTypes.bool
};