import axios from "./clientSetup";

export function adminSetUserEnabled(userId, enabled){
    const request = axios
        .patch(`/api/admin/users/${userId}/enabled`, {enabled});

    return request;
}

export function adminDeleteUser(userId){
    const request = axios
        .delete(`/api/admin/users/${userId}`);

    return request;
}

export function adminUpdateUser(id, userData){
    const request = axios
        .put(`/api/admin/users/${id}`, userData);

    return request;
}

export function adminCreateUser(userData){
    const request = axios
        .post('/api/admin/users', userData);

    return request;
}

export function adminGetUserDetails(id){
    const request = axios.get(`/api/admin/users/${id}`);

    return request;
}

export function adminFetchUserPicker(pageSize, usernameSearch){
    const request = axios.get(`/api/admin/users?page=1&pageSize=${pageSize}&sortBy=username&sortDirection=asc&username=${usernameSearch}`);

    return request;
}

export function adminGetUsers(page, pageSize, orderBy, order, filterEmail){
    const request = axios.get(`/api/admin/users?page=${page}&pageSize=${pageSize}&sortBy=${orderBy}&sortDirection=${order}&email=${filterEmail}`);

    return request;
}

export function adminGetBaseUrlList(page, pageSize, orderBy, order, filterDomain){
    const request = axios.get(`/api/admin/bases?page=${page}&pageSize=${pageSize}&sortBy=${orderBy}&sortDirection=${order}&domain=${filterDomain}`);

    return request;
}

export function adminFetchBaseUrlPicker(pageSize, filterDomain){
    const request = axios.get(`/api/admin/bases?page=1&pageSize=${pageSize}&sortBy=domain&sortDirection=asc&domain=${filterDomain}`);

    return request;
}

export function adminGetBaseUrlDetails(id){
    const request = axios.get(`/api/admin/bases/${id}`);

    return request;
}

export function adminPathBaseUrl(id, field, value){
    const data = {};
    data[field] = value;
    const request = axios
        .patch(`/api/admin/bases/${id}/${field}`, data);

    return request;
}

export function adminUpdateBaseUrl(id,data){
    const request = axios
        .put(`/api/admin/bases/${id}`, data);

    return request;
}

export function adminCreateBaseUrl(data){
    const request = axios
        .post('/api/admin/bases', data);

    return request;
}

export function adminDeleteBaseUrl(id){
    const request = axios
        .delete(`/api/admin/bases/${id}`);

    return request;
}

export function adminGetClientList(page, pageSize, orderBy, order, searchValue){
    const request = axios.get(`/api/admin/clients?page=${page}&pageSize=${pageSize}&sortBy=${orderBy}&sortDirection=${order}&title=${searchValue}`);

    return request;
}

export function adminGetClientDetails(id){
    const request = axios.get(`/api/admin/clients/${id}`);

    return request;
}

export function adminCreateClients(data){
    const request = axios
        .post('/api/admin/clients', data);

    return request;
}

export function adminUpdateClient(id,data){
    const request = axios
        .put(`/api/admin/clients/${id}`, data);

    return request;
}

export function adminPathClient(id, field, value){
    const data = {};
    data[field] = value;
    const request = axios
        .patch(`/api/admin/clients/${id}/${field}`, data);

    return request;
}

export function adminResetClientStatsReporting(id){
    const request = axios
        .patch(`/api/admin/clients/${id}/reset-stats-report`, {});

    return request;
}

export function adminDeleteClient(id){
    const request = axios
        .delete(`/api/admin/clients/${id}`);

    return request;
}