import { Alert } from "@mui/lab";
import { Divider } from "@mui/material";
import PropTypes from "prop-types";

const AlertMessage = ({message, severity}) => (message ? (<div><Alert severity={severity}>{message}</Alert><Divider sx={{ my: 3 }} /></div>) : null);

AlertMessage.propTypes = {
    message: PropTypes.string,
    severity: PropTypes.string
}

export default AlertMessage;