import {Helmet} from "react-helmet-async";
import {Card, Container, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import Scrollbar from "../../components/scrollbar";
import UserForm from "../../sections/user/admin-form/UserForm";
import {adminCreateUser} from "../../api/adminApi";



const StyledContent = styled('div')(({ theme }) => ({
    display: 'block',
    justifyContent: 'left',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
}));

export default function UserNewPage() {
    const navigate = useNavigate();

    const processUserCreate = (userData) => {
        const request = adminCreateUser(userData)
            request
                .then(() => {navigate('/admin/users/', { replace: true });});
        return request;
    };

    return (
        <>
            <Helmet>
                <title> New User </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New User
                    </Typography>
                </Stack>
                <Card>
                    <Scrollbar>
                        <StyledContent>
                            <UserForm formSubmitCallback={processUserCreate} actionLabel="Create" rePasswordEnable />
                        </StyledContent>
                    </Scrollbar>
                </Card>
            </Container>
        </>
    );
}